const servicesPreviewData = [
	{
		title: 'General Advisory Services',
		description:
			'Comprehensive Web3 consultancy: SWOT analysis, project needs evaluation, overall project assessment, with 2-3 inclusive discussions',
	},
	{
		title: 'Marketing',
		description:
			'Tailored marketing solutions: Evaluation of marketing needs, general marketing strategy, and specific product marketing strategy.',
	},
	{
		title: 'Tokenomics',
		description: 'Comprehensive evaluation of distribution and mechanics',
	},
	{
		title: 'PR/Communication',
		description:
			'Dynamic community management, collaborative PR, and growth strategy services offered at competitive rates. Professional document creation available for a streamlined presentation of your project',
	},
	{
		title: 'Promo',
		description:
			'Engage your audience with captivating visuals: short and long promo videos, extended presentations, custom banners, infograms, and visual identity packages available.',
	},
	{
		title: 'Merch Design',
		description:
			"Elevate your brand with custom merch designs: t-shirts, hoodies, and more. Tailored options available to suit your project's unique style and needs.",
	},
	{
		title: 'Dev Works',
		description:
			"From landing pages to Dapps, Smart Contract development, and DevOps automation—unlock tailored solutions for your project's success, including professional website design and visual identity packages",
	},
	{
		title: 'Audio Production',
		description:
			'Elevate your content with custom sound effects and music production, expertly synced with your video.',
	},
	{
		title: 'Video Production',
		description:
			'High-quality video production services to present your project/product, tailored to your needs.',
	},
	{
		title: 'Support Services',
		description:
			'Explore AI NFT/SFT collections, advertising options, hand-drawn NFTs, and professional 3D rendering and design services.',
	},
];

export default servicesPreviewData;
