import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Contact.scss';

const Contact = () => {
	const initialValues = {
		name: '',
		email: '',
		message: '',
	};

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.email('Invalid email format')
			.required('Email is required'),
		message: Yup.string().required('Message is required'),
	});

	const handleSubmit = async (values, { setSubmitting, resetForm }) => {
		try {
			const response = await fetch('https://formspree.io/f/manwydwn', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(values),
			});

			if (response.ok) {
				alert('Message sent successfully!');
				resetForm();
			} else {
				alert('Failed to send message, please try again.');
			}
		} catch (error) {
			alert('Failed to send message, please try again.');
		}

		setSubmitting(false);
	};

	return (
		<div className='contact-container'>
			<h2>Contact Us</h2>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<div className='form-group'>
							<label htmlFor='name'>Name</label>
							<Field type='text' id='name' name='name' />
							<ErrorMessage name='name' component='div' className='error' />
						</div>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<Field type='email' id='email' name='email' />
							<ErrorMessage name='email' component='div' className='error' />
						</div>
						<div className='form-group'>
							<label htmlFor='message'>Message</label>
							<Field as='textarea' id='message' name='message' />
							<ErrorMessage name='message' component='div' className='error' />
						</div>
						<button type='submit' disabled={isSubmitting}>
							Send Message
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default Contact;
