import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import FireParticles from './Components/FireParticles/FireParticles';
import ScrollToTop from './utils/ScrollToTop';
import PageViewLogger from './utils/PageViewLogger';
import './App.scss';

const App = () => {
	return (
		<Router>
			<ScrollToTop />
			<FireParticles />
			<PageViewLogger />
			<div className='app-content'>
				<Header />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/services' element={<Services />} />
					<Route path='/contact' element={<Contact />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
