import React from 'react';
import './Clients.scss';

const clients = [
	{
		href: 'https://artcpaclub.com/',
		src: '/logos/artcpa.png',
		alt: 'ArtCPA',
	},
	{
		href: 'https://estar.games/',
		src: '/logos/estar.png',
		alt: 'EstarGames',
	},
	{
		href: 'https://lunarpay.finance/',
		src: '/logos/lunar.png',
		alt: 'Lunar Pay',
	},
	{
		href: 'https://midaschain.ai/',
		src: '/logos/midas.png',
		alt: 'Midas Chain',
	},
	{
		href: 'https://peerme.io/',
		src: '/logos/peerme.png',
		alt: 'PeerMe',
	},
	{
		href: '#',
		src: '/logos/uniplay.png',
		alt: 'UniPlay',
	},
	{
		href: 'https://vampirewars.io/',
		src: '/logos/vampirewars.png',
		alt: 'Vampire Wars',
	},
	{
		href: 'https://vampirewars.io/',
		src: '/logos/trad3ex.png',
		alt: 'Trad3ex',
	},
];

const Clients = () => (
	<div className='clients'>
		<h2>Our Clients</h2>
		<div className='clients-logos'>
			<div className='logos-wrapper'>
				{clients.map((client, index) => (
					<a
						key={index}
						href={client.href}
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={client.src} alt={client.alt} />
					</a>
				))}
			</div>
			<div className='logos-wrapper'>
				{clients.map((client, index) => (
					<a
						key={index + clients.length} // Ensure unique keys
						href={client.href}
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={client.src} alt={client.alt} />
					</a>
				))}
			</div>
		</div>
	</div>
);

export default Clients;
