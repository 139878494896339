import React, { useState } from 'react';
import servicesData from '../../data/servicesData';
import './Services.scss';

const Services = () => {
	const [filter, setFilter] = useState('All');

	const filteredServices =
		filter === 'All'
			? servicesData
			: servicesData.filter((service) => service.category === filter);

	return (
		<div className='container'>
			<div className='services-page'>
				<h1>Our Services</h1>
				<div className='filter-buttons'>
					<button
						className={filter === 'All' ? 'active' : ''}
						onClick={() => setFilter('All')}
					>
						All
					</button>
					<button
						className={filter === 'General Advisory Services' ? 'active' : ''}
						onClick={() => setFilter('General Advisory Services')}
					>
						General Advisory Services
					</button>
					<button
						className={filter === 'Marketing' ? 'active' : ''}
						onClick={() => setFilter('Marketing')}
					>
						Marketing
					</button>
					<button
						className={filter === 'Tokenomics' ? 'active' : ''}
						onClick={() => setFilter('Tokenomics')}
					>
						Tokenomics
					</button>
					<button
						className={filter === 'PR/Communication' ? 'active' : ''}
						onClick={() => setFilter('PR/Communication')}
					>
						PR/Communication
					</button>
					<button
						className={filter === 'Promo Services' ? 'active' : ''}
						onClick={() => setFilter('Promo Services')}
					>
						Promo Services
					</button>
					<button
						className={filter === 'Merch Design' ? 'active' : ''}
						onClick={() => setFilter('Merch Design')}
					>
						Merch Design
					</button>
					<button
						className={filter === 'Developer works' ? 'active' : ''}
						onClick={() => setFilter('Developer works')}
					>
						Developer works
					</button>
					<button
						className={filter === 'Audio Production' ? 'active' : ''}
						onClick={() => setFilter('Audio Production')}
					>
						Audio Production
					</button>
					<button
						className={filter === 'Video Production' ? 'active' : ''}
						onClick={() => setFilter('Video Production')}
					>
						Video Production
					</button>
					<button
						className={filter === 'Support Services' ? 'active' : ''}
						onClick={() => setFilter('Support Services')}
					>
						Support Services
					</button>
				</div>
				<div className='services-summary'>
					{filteredServices.map((service, index) => (
						<div key={index} className='service-card'>
							<h2>{service.title}</h2>
							<ul>
								{service.description.map((item, idx) => (
									<li key={idx}>{item}</li>
								))}
							</ul>
							{service.price && <p className='price'>Price: {service.price}</p>}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Services;
