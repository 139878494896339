import React from 'react';
import './Footer.scss';

const Footer = () => {
	return (
		<footer className='footer'>
			<p>
				&copy; 2024 <span className='gradient-text'>Blazing Web3</span>. All
				rights reserved.
			</p>
			<p>
				Made with <span className='heart'>❤</span> by{' '}
				<span className='gradient-text'>NETHLAB</span>
			</p>
		</footer>
	);
};

export default Footer;
