import React from 'react';
import { Link } from 'react-router-dom';
import ServicesPreview from '../../Components/ServicesPreview/ServicesPreview';
import Testimonials from '../../Components/Testimonials/Testimonials';
import Clients from '../../Components/Clients/Clients';
import './Home.scss';

const Home = () => (
	<div className='home-container'>
		<div className='welcome-message'>
			<h2>Blazing WEB3 Services</h2>
			<h1>The ONE STOP You Need!</h1>
			<p className='intro-text'>
				Welcome to Blazing Web3 Services, a true one stop services platform for
				your web3 journey. Discover a team that is focused on your needs and
				potential. Let's embark on this journey together and blaze a trail to
				remember in Web3.
			</p>
		</div>
		<ServicesPreview />
		<Testimonials />
		<Clients />
		<div className='contact-section'>
			<h2>Let's Get In Touch</h2>
			<p>
				Ready to embark on your Web3 journey with us? Reach out and let's make
				something amazing together!
			</p>
			<Link to='/contact' className='contact-button'>
				Contact Us
			</Link>
		</div>
	</div>
);

export default Home;
