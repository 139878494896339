import React, { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import './FireParticles.scss';

const FireParticles = () => {
	const [init, setInit] = useState(false);

	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	return (
		<>
			{init && (
				<Particles
					id='tsparticles'
					options={{
						fullScreen: {
							enable: true,
							zIndex: -1,
						},
						particles: {
							number: {
								value: 100,
								density: {
									enable: true,
									value_area: 800,
								},
							},
							color: {
								value: ['#ff4500', '#ffd700', '#ff8c00'],
							},
							shape: {
								type: 'circle',
							},
							opacity: {
								value: 0.5,
								random: true,
								anim: {
									enable: true,
									speed: 1,
									opacity_min: 0.1,
									sync: false,
								},
							},
							size: {
								value: 5,
								random: true,
								anim: {
									enable: true,
									speed: 10,
									size_min: 0.1,
									sync: false,
								},
							},
							links: {
								enable: false,
							},
							move: {
								enable: true,
								speed: 3,
								direction: 'top',
								random: true,
								straight: false,
								outModes: {
									default: 'out',
								},
								bounce: false,
								attract: {
									enable: false,
								},
							},
						},
						interactivity: {
							events: {
								onHover: {
									enable: true,
									mode: 'bubble',
								},
								onClick: {
									enable: true,
									mode: 'repulse',
								},
								resize: true,
							},
							modes: {
								bubble: {
									distance: 250,
									size: 0,
									duration: 2,
									opacity: 0,
									speed: 3,
								},
								repulse: {
									distance: 400,
									duration: 0.4,
								},
							},
						},
						retina_detect: true,
					}}
				/>
			)}
		</>
	);
};

export default FireParticles;
