import React from 'react';
import { Link } from 'react-router-dom';
import servicesPreviewData from '../../data/servicesPreviewData';
import './ServicesPreview.scss';

const ServicesPreview = () => (
	<div className='services-preview'>
		<h2>Our Services</h2>
		<div className='services-summary'>
			{servicesPreviewData.map((service, index) => (
				<div key={index} className='service-card'>
					<h2>{service.title}</h2>
					<p>{service.description}</p>
				</div>
			))}
		</div>
		<Link to='/services' className='view-more-button'>
			View More Services
		</Link>
	</div>
);

export default ServicesPreview;
