import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageViewLogger = () => {
	const location = useLocation();

	useEffect(() => {
		window.gtag('config', 'G-XXXXXXXXXX', {
			page_path: location.pathname,
		});
	}, [location]);

	return null;
};

export default PageViewLogger;
