import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Testimonials.scss';

const testimonials = [
	{
		text: "The best way to describe blazing is that they offer a professional service with an amazing result and let's not forget the human touch as well. During our continous collaboration we received the best service provided in a timely manner. Last but not least we highly recommend their services as we trust that they will exceed your expectations as they did ours.",
		author: 'Ciprian Popovici, Founder & CEO of ArtCPA Club',
	},
	{
		text: "Blazing's a wonderful partner to have on your web3 journey. If you need something, they'll get it done.",
		author: 'Micha Vie, Founder of PeerMe.io',
	},
	{
		text: 'Over the past 3 months, our partnership with Blazing has been nothing short of transformative. The team at Blazing has consistently demonstrated their ability to not only understand our needs but also to anticipate them, fostering an environment of trust and collaboration that is rare to find. We are grateful for their contributions, that helped us to focus only on our mission and look forward to continuing our successful partnership.',
		author: 'Alexandru Sitaru, Founder & CEO of Estar Games',
	},
];

const Testimonials = () => (
	<div className='carousel-wrapper'>
		<h2>What People Say</h2>
		<Carousel
			showThumbs={false}
			showStatus={false}
			infiniteLoop
			useKeyboardArrows
			autoPlay
		>
			{testimonials.map((testimonial, index) => (
				<div key={index} className='testimonial'>
					<p>{testimonial.text}</p>
					<h3>{testimonial.author}</h3>
				</div>
			))}
		</Carousel>
	</div>
);

export default Testimonials;
